import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

function TeamRespect() {
    return (
        <StaticImage
            src="../../images/TeamRespect.jpg"
            alt="Shaking gentlemen's hand in celebration"
            placeholder="blurred"
            layout="constrained"
        />
    );
}

export default TeamRespect;
