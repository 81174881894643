import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

function AbstractSelf() {
    return (
        <StaticImage
            src="../../images/AbstractSelf.jpg"
            alt="An abstract self portrait"
            placeholder="blurred"
            layout="constrained"
        />
    );
}

export default AbstractSelf;
