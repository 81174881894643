import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

function TeamCelebration() {
    return (
        <StaticImage
            src="../../images/TeamCelebration.jpg"
            alt="Team celebrating at the office"
            placeholder="blurred"
            layout="constrained"
        />
    );
}

export default TeamCelebration;

