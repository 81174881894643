import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

function RobotFocusAtNight() {
    return (
        <StaticImage
            src="../../images/RobotFocusAtNight.jpg"
            alt="Robot standing in front of lights at night"
            placeholder="blurred"
            layout="constrained"
        />
    );
}

export default RobotFocusAtNight;
