import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const ScruffyMan = () => {
    return (
        <StaticImage
            src="../../images/ScruffyMan.jpg"
            alt="Scruffy man holding a human kindness sign"
            placeholder="blurred"
            layout="constrained"
        />
    );
}

export default ScruffyMan;
