import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

function GreenCare() {
  return (
    <StaticImage
      src="../../images/green_care.jpg"
      alt="Bird eating food from a persons hand"
      placeholder="blurred"
      layout="constrained"
    />
  );
}

export default GreenCare;
