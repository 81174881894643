import React, { useRef, useState } from 'react';
import Layout from '../components/Layout';
import BannerMap from '../components/BannerMap';
import GreenCare from '../components/static_images/GreenCare';
import ContentWrapper from '../components/ContentWrapper';
import Grid from '../components/Grid';
import ContentBanner from '../components/ContentBanner';
import useGeometryReader from '../hooks/useGeometryReader';
import TechCubes from '../components/static_images/TechCubes';
import HandShake from '../components/static_images/HandShake';
import MindStairs from '../components/static_images/MindStairs';
import RobotBench from '../components/static_images/RobotBench';
import ScruffyMan from '../components/static_images/ScruffyMan';
import UxMap from '../components/static_images/UxMap';
import TeamCelebration from '../components/static_images/TeamCelebration';
import AbstractSelf from '../components/static_images/AbstractSelf';
import VrTennis from '../components/static_images/VrTennis';
import TeamRespect from '../components/static_images/TeamRespect';
import RobotFocusAtNight from '../components/static_images/RobotFocusAtNight';

const InitBanners = [
  {
    title: 'Thorough',
    subtitle: 'T-----------',
    adaption: 'standard-copy',
    coverRemoved: true,
    activeCard: {
      title: 'Thorough',
      subtitle: () => <p>When we build, we never leave anything behind. We follow through until our clients' complete satisfaction. What makes us even happier is if our clients are satisfied with the result, as we were.</p>,
      adaption: 'standard-reverse',
      classNames: 'content-banner content-banner--bottom-left-diagonal-gradient',
      child: GreenCare,
    },
  },
  {
    title: 'Unwavering',
    subtitle: '-U----------',
    adaption: 'standard-copy',
    activeCard: {
      title: 'Unwavering',
      subtitle: () => <p>Unwavering commitment from design through delivery. We don't cut corners when it comes to designing tools or giving them to you.</p>,
      adaption: 'standard',
      classNames: 'content-banner content-banner--bottom-right-diagonal-gradient',
      child: TechCubes,
    },
  },
  {
    title: 'RELIABLE',
    subtitle: '--R---------',
    adaption: 'standard-copy',
    activeCard: {
      title: 'RELIABLE',
      subtitle: () => <p>We're proud to say we have a excellent reliability rating from clients. Don't settle for an unreliable solution.Trust Turing Lamarr for all your IT needs.</p>,
      adaption: 'standard-reverse',
      classNames: 'content-banner content-banner--bottom-right-diagonal-gradient',
      child: HandShake,
    },
  },
  {
    title: 'INTELLECTUAL',
    subtitle: '---I--------',
    adaption: 'standard-copy',
    activeCard: {
      title: 'INTELLECTUAL',
      subtitle: () => <p>Build intellectual software that works as a good interface between humans and computers.</p>,
      adaption: 'standard',
      classNames: 'content-banner content-banner--bottom-right-diagonal-gradient',
      child: MindStairs,
    },
  },
  {
    title: 'NOVEL',
    subtitle: '----N--------',
    adaption: 'standard-copy',
    activeCard: {
      title: 'NOVEL',
      subtitle: () => <p>Do you need something new? We're doing what you've never seen before. Turring Lamarr is a leading developer of novel software solutions, combining creativity with technical expertise to push the limits of what is possible.</p>,
      adaption: 'standard-reverse',
      classNames: 'content-banner content-banner--bottom-right-diagonal-gradient',
      child: RobotBench,
    },
  },
  {
    title: 'GENUINE',
    subtitle: '-----G------',
    adaption: 'standard-copy',
    activeCard: {
      title: 'GENUINE',
      subtitle: () => <p>Genuine. Customers are always provided with honest advice and find the solution best for you and the company.</p>,
      adaption: 'standard',
      classNames: 'content-banner content-banner--bottom-right-diagonal-gradient',
      child: ScruffyMan,
    },
  },
  {
    title: 'LUCID',
    subtitle: '------L-----',
    adaption: 'standard-copy',
    activeCard: {
      title: 'LUCID',
      subtitle: () => <p>Lucid is clear. Our UX is easy to understand because our design is expressed in a simple way.</p>,
      adaption: 'standard-reverse',
      classNames: 'content-banner content-banner--bottom-right-diagonal-gradient',
      child: UxMap,
    },
  },
  {
    title: 'ACHIEVEMENT',
    subtitle: '-------A----',
    adaption: 'standard-copy',
    activeCard: {
      title: 'ACHIEVEMENT',
      subtitle: () => <p>Turn data insight into actionable outcomes with our powerhouse KPI analytics. Our team is proud to be a part of every success story we’re a part of—yours included.</p>,
      adaption: 'standard',
      classNames: 'content-banner content-banner--bottom-right-diagonal-gradient',
      child: TeamCelebration,
    },
  },
  {
    title: 'MERIT',
    subtitle: '--------M---',
    adaption: 'standard-copy',
    activeCard: {
      title: 'MERIT',
      subtitle: () => <p>The merit cannot be bought — it must be earned every day. That's why merit is so important—it gives each person a new level of security and power. Now, every detail matters.</p>,
      adaption: 'standard-reverse',
      classNames: 'content-banner content-banner--bottom-right-diagonal-gradient',
      child: AbstractSelf,
    },
  },
  {
    title: 'ADMIRE',
    subtitle: '---------A--',
    adaption: 'standard-copy',
    activeCard: {
      title: 'ADMIRE',
      subtitle: () => <p>We adore every moving piece of the software process. Join us as we create something that will leave you in awe.</p>,
      adaption: 'standard',
      classNames: 'content-banner content-banner--bottom-right-diagonal-gradient',
      child: VrTennis,
    },
  },
  {
    title: 'RESPECTED',
    subtitle: '----------R-',
    adaption: 'standard-copy',
    activeCard: {
      title: 'RESPECTED',
      subtitle: () => <p>When developing Software by Turning Lamarr, we do it the right way—with respect. Our team respects and loves what they do; while developing products, they also build them as if they were their own.</p>,
      adaption: 'standard-reverse',
      classNames: 'content-banner content-banner--bottom-right-diagonal-gradient',
      child: TeamRespect,
    },
  },
  {
    title: 'READY',
    subtitle: '-----------R',
    adaption: 'standard-copy',
    activeCard: {
      title: 'READY',
      subtitle: () => <p>We are. Are you?</p>,
      adaption: 'standard',
      classNames: 'content-banner content-banner--bottom-right-diagonal-gradient',
      child: RobotFocusAtNight,
    },
  },
];

const removeCover = (banners, y, bannerHeight) => {
  const newBanners = banners.map((banner, index) => {
    const bannerYOffset = -1 * bannerHeight * (index - 1);
    const shouldRemoveCover = y <= bannerYOffset;
    if (shouldRemoveCover) {
      return {
        ...banner,
        // TODO: remove cover
        // classNames: 'content-banner',
        coverRemoved: true,
      };
    }
    return banner;
  });
  return newBanners;
};

const getActiveCard = (banners) => {
  const reversedBanners = [...banners].reverse();
  const activeBanner = reversedBanners.find((banner) => banner.coverRemoved);
  return activeBanner ? activeBanner.activeCard : null;
};

const getInitialY = () => {
  if (typeof window !== 'undefined') {
    return parseInt(sessionStorage.getItem('initialY'));
  }
  return NaN;
};

// The api implements parallax for each banner.
// We are mapping here to override instead of adding extra logic to the abstraction.
const bannersWithCommonProps = InitBanners.map((banner, index) => {
  if (index % 2 === 1) {
    return ({
      ...banner,
      parallaxProps: {},
      classNames: 'content-banner',
      activeCard: {
        ...banner.activeCard,
        classNames: `content-banner content-banner--bottom-right-diagonal-gradient--80 z-index-${index + 1}`,
      },
    })
  }
  return ({
    ...banner,
    classNames: `content-banner  `,
    parallaxProps: {},
    activeCard: {
      ...banner.activeCard,
      classNames: `content-banner content-banner--bottom-left-diagonal-gradient--80 dark-background-2  z-index-${index + 1}`,
    },

  })
});

function About() {
  const [banners, setBanners] = useState(bannersWithCommonProps);
  const initialY = useRef(getInitialY());
  const {
    ref,
  } = useGeometryReader((geometryInfo, updateGeometryInfo) => {
    if (isNaN(initialY.current)) {
      initialY.current = geometryInfo.y;
      sessionStorage.setItem('initialY', initialY.current);
    }
    const { y, height } = geometryInfo;
    const bannerHeight = height / 2;
    const newBanners = removeCover(banners, bannerHeight - initialY.current + y, bannerHeight);
    // TODO: optimize state updates with updateGeometryInfo only rerender via the callback rather than setBanners
    setBanners(newBanners);
  });
  const { child: activeCardChild, ...activeCardProps } = getActiveCard(banners);
  return (
    <Layout className="about">
      <div ref={ref} />
      {/* <ContentWrapper className='about__heading'>
        <ContentBanner>
          <h1>we are who we are</h1>
        </ContentBanner>
      </ContentWrapper> */}
      <div className="about__sections">

        {
          bannersWithCommonProps.map((banner, index) => {

            return (
              <>
                <ContentWrapper>
                  <ContentBanner {...banner.activeCard} childrenClassNames="height-60-md height-50">
                    {banner.activeCard.child()}
                  </ContentBanner>
                </ContentWrapper>

              </>
            )
          })
        }
      </div>
      <div className="about__titles">
        {
          banners.map(banner => {
            const splitSubtitle = banner.subtitle.split('');
            return <Grid className={banner.classNames}>
              {
                splitSubtitle.map((letter, index) => {
                  if (letter !== '-') {
                    return <h2>{letter}</h2>
                  }
                  return <span></span>
                })
              }
            </Grid>
          })
        }

        {/* <BannerMap banners={banners} /> */}
      </div>

    </Layout >
  );
}

export default About;
