import { useDebugValue, useEffect, useRef, useState } from "react";
const useGeometryReader = (scrollCallback) => {
    const ref = useRef(); 
    const unobservedGeometryInfo = useRef({}); 
    const [ observedGeometryInfo, setObservedGeometryInfo ] = useState(null);

    const updateObservedGeometryInfo = () => {
        setObservedGeometryInfo(unobservedGeometryInfo.current);
    };

    const onScroll = () => {
        unobservedGeometryInfo.current = ref?.current?.getBoundingClientRect();
        scrollCallback(
            unobservedGeometryInfo.current, 
            updateObservedGeometryInfo
        );
    };

    const {
        bottom,
        height,
        left,
        right,
        top,
        width,
        x,
        y,
    } = unobservedGeometryInfo.current;

    useDebugValue({ 
        bottom, 
        height,
        left,
        right,
        top,
        width,
        x,
        y,
    });

    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);
    return  { 
        ref, 
        observedGeometryInfo 
    } 
}

export default useGeometryReader;
