import React from "react";
const resolveFuncStringProp = (prop, element) => {
    if (typeof prop === "function") {
        return prop();
    }
    switch (element) {
        case "h1": <h2>{prop}</h2>
        case "h2":
            return <h2>{prop}</h2>
        default:
            return prop;
    }
}

export default resolveFuncStringProp;