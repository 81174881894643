import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

function RobotBench() {
    return (
        <StaticImage
            src="../../images/RobotBench.jpg"
            alt="Robot sitting on a bench with a computer"
            placeholder="blurred"
            layout="constrained"
        />
    );
}

export default RobotBench;
