import React from 'react';
import { useParallax } from 'react-scroll-parallax';
import resolveFuncStringProp from '../helpers/resolveFuncStringProp';

function ContentBanner({
  title,
  subtitle,
  children,
  adaption,
  classNames = '',
  parallaxRef,
  childrenClassNames = '',
}) {
  const resolveRef = {
    ...(parallaxRef ? { ref: parallaxRef } : {}),
  }
  return (
    <div className={`content-banner ${classNames}`} adaption={adaption}>
      {
        title &&
        <div className="content-banner__title" {...resolveRef}>
          {resolveFuncStringProp(title, 'h1')}
        </div>
      }
      {
        subtitle &&
        <div className="content-banner__subtitle">
          {resolveFuncStringProp(subtitle, 'h2')}
        </div>
      }
      {
        children &&
        <div className={`content-banner__children ${childrenClassNames}`}>
          {children && resolveFuncStringProp(children)}
        </div>
      }
    </div>
  );
}

export const ContentBannerWithOutParallax = (props) => {
  return <ContentBanner {...props} />;
}

const ContentBannerWithParallax = (props) => {
  const {
    parallaxProps,
    ...rest
  } = props;
  const { ref } = useParallax({
    ...parallaxProps,
  });
  const mergedProps = {
    ...rest,
    parallaxRef: ref,
  };
  return <ContentBanner {...mergedProps} />
}


export default ContentBannerWithParallax;
