import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

function TechCubes() {
    return (
        <StaticImage
            src="../../images/tech_cubes.jpg"
            alt="cubes in space"
            placeholder="blurred"
            layout="constrained"
        />
    );
}

export default TechCubes;
