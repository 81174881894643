import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

function MindStairs() {
    return (
        <StaticImage
            src="../../images/MindStairs.jpg"
            alt="A person climbing a stairs on a sculpture of a head"
            placeholder="blurred"
            layout="constrained"
        />
    );
}

export default MindStairs;
